import React from "react";
import { graphql, navigate } from "gatsby";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Button from "../components/Button/Button";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import LatestArticle from "../components/LatestArticle/LatestArticle";
import Footer from "../components/Footer/Footer";
import BlogSearch from "../components/BlogSearch/BlogSearch";

import "./BlogPage.css";

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allContentfulArticle(limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          id
          title
          metaTitle
          metaDescription {
            childMarkdownRemark {
              html
            }
          }
          slug
          thumbnailImageUrl
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

const BlogPage = ({ data, pageContext }) => {
  const articles = data.allContentfulArticle.edges;
  const { skip, limit } = pageContext;

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div>
        <Section>
          <SectionHeading>BLOG</SectionHeading>
          <BlogSearch />
          <div className="blogs">
            {articles.map((article) => (
              <div className="blog-post" key={article.node.id}>
                <LatestArticle latestArticle={article.node} />
              </div>
            ))}
          </div>
          <div className="pagination-buttons">
            {skip > 0 ? (
              <Button
                onClick={() =>
                  navigate(skip > limit ? `/blog/${skip / limit}` : "/blog")
                }
              >
                Previous
              </Button>
            ) : (
              <div />
            )}
            {skip + limit < data.allContentfulArticle.totalCount ? (
              <Button onClick={() => navigate(`/blog/${skip / limit + 2}`)}>
                Next
              </Button>
            ) : (
              <div />
            )}
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default BlogPage;

export const Head = ({ pageContext }) => {
  const { skip, limit } = pageContext;
  return (
    <SEO
      index={skip === 0}
      follow={skip === 0}
      title={"AI Product Reviews Blog"}
      description={
        "Welcome to our Ai Product Review Blog. We cover tips, guides, discounts and a whole lot more so give our blog a read!"
      }
    />
  );
};
